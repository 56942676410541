import React, { useState } from "react";
import "../../styles/analyticsSidebar.css";
import { Image } from "react-bootstrap";
import subscriptionSidebarLogo from "../../../../assets/images/brands/chatbot-sidebar-logo.svg";
import subscriptionChatbotLogo from "../../../../assets/modulis/modulid-small-icon.png";
import "remixicon/fonts/remixicon.css";

const MasterCssSidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className={`analytics-plans-sidebar ${isOpen ? "open" : "closed"}`}>
        <div className="analytics-plans-sidebar-header">
          {isOpen ? (
            <Image
              className="ms-5"
              src={subscriptionSidebarLogo}
              alt="subscription logo"
              width="200px"
              height="47px"
            />
          ) : (
            <Image
              className="ms-2"
              src={subscriptionChatbotLogo}
              height="47px"
              alt="chatbot logo"
            />
          )}
          <div
            className="analytics-plans-sidebar-back-button"
            onClick={toggleSidebar}
          >
            {isOpen ? (
              <i className="ri-arrow-left-line"></i>
            ) : (
              <i className="ri-arrow-right-line"></i>
            )}
          </div>
        </div>
        <div>
          <hr className="analytics-plans-sidebar-header-bottom-line" />
          {isOpen && (
            <div className="subscription-sidebar-header-ai-subscription">
              MasterCss
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MasterCssSidebar;
